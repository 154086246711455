import React from 'react';
import './styles/CareerPaths.css';
import { Link } from 'react-router-dom';
import coursesInfo from '../../data/coursesInfo.json';

const CareerPaths = () => {
  const courses = coursesInfo[0].courses;

  const getCoursesBySubCategory = (subCategory) => {
    return courses.filter((course) => course.subCategory === subCategory);
  };

  const renderCourseLinks = (courses, className) =>
    courses.map((course) => (
      <Link key={course.id} to={course.courseLink} className={className}>
        {course.courseName}
      </Link>
    ));

  const webDevelopmentCourses = getCoursesBySubCategory('web development');
  const javaCourses = getCoursesBySubCategory('java');
  const cybersecurityCourses = getCoursesBySubCategory(
    'cybersecurity engineering'
  );

  return (
    <div className="container-career-paths">
      <h1>Engineer Career Paths</h1>
      <div className="paths-container">
        <div className="path">
          <div className="title-in-circle front-end">
            Web Developer
            <a href="https://www.glassdoor.com/Salaries/front-end-software-engineer-salary-SRCH_KO0,27.htm">
              Average Salary 143K
            </a>
            <p className="paths-duration">Duration: 7 months</p>
          </div>
          {renderCourseLinks(webDevelopmentCourses, 'course-name-light')}
        </div>

        <div className="path">
          <div className="title-in-circle back-end">
            Back-End Software Engineer
            <a href="https://www.glassdoor.com/Salaries/backend-engineer-salary-SRCH_KO0,16.htm#:~:text=%24157K,-%2Fyr&text=How%20accurate%20is%20a%20total,our%20pay%20estimates%20over%20time.">
              Average Salary 146K
            </a>
            <p className="paths-duration">Duration: 6 months</p>
          </div>
          {renderCourseLinks(javaCourses, 'course-name-medium')}
        </div>

        <div className="path">
          <div className="title-in-circle full-stack">
            Full-Stack Software Engineer
            <a href="https://www.glassdoor.com/Salaries/full-stack-software-engineer-salary-SRCH_KO0,28.htm">
              Average Salary 154K
            </a>
            <p className="paths-duration">Duration: 7 or 9 months</p>
          </div>

          <div className="course-row line-down-target">
            {renderCourseLinks(
              webDevelopmentCourses.slice(0, 2),
              'course-name-light'
            )}
          </div>

          <div className="full-stack-container">
            <div className="column">
              {renderCourseLinks(
                webDevelopmentCourses.slice(2),
                'course-name-light-fs vertical-line-target'
              )}
            </div>

            <div className="column">
              {renderCourseLinks(
                javaCourses,
                'course-name-medium-fs vertical-line-target'
              )}
            </div>
          </div>
        </div>

        <div className="path">
          <div className="title-in-circle cybersecurity">
            Cybersecurity Engineer
            <a href="https://www.glassdoor.com/Salaries/cybersecurity-engineer-salary-SRCH_KO0,22.htm">
              Average Salary 145K
            </a>
            <p className="paths-duration">Duration: 10 months</p>
          </div>
          {renderCourseLinks(cybersecurityCourses, 'course-name-green')}
        </div>
      </div>
    </div>
  );
};

export default CareerPaths;
