import React from 'react';
import './styles/ClassesIntroPages.css';
import { Link } from 'react-router-dom';

const WebDevelopment = () => {
  return (
    <div className="ClassIntro-container">
      <div className="ClassIntro-background">
        <div className="ClassIntro-title-section">
          <div className="ClassIntro-title-content">
            <h1>Web Development</h1>
            <p className="ClassIntro-description">
              This set of courses is the perfect place to start for beginners or
              for existing engineers who want to add Web Development to their.{' '}
              <Link to="/101IntroToWebDevelopment" className="Course-req-link">
                101 Intro to Web Development
              </Link>{' '}
              begins with the basics and requires no coding experience. For
              existing engineers or those who have prior knowledge,{' '}
              <Link to="/301IntermJSandWebDev" className="Course-req-link">
                301 Intermediate JavaScript and Web Development
              </Link>{' '}
              is for you. These last two courses focus on building Web
              Development skills to an expert level teaching advanced JavaScript
              concepts, Cloud Computing with AWS, and using modern AI tools — an
              essential skill for anyone interested in tech.
            </p>
          </div>
          <div className="ClassIntro-image-container">
            <img
              className="ClassIntro-img"
              src="webDevelopment.jpg"
              alt="coding in classroom"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;
