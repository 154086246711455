import React from 'react';
import './styles/ClassesIntroPages.css';

const Cybersecurity = () => {
  return (
    <div className="ClassIntro-background">
      <div className="ClassIntro-container">
        <div className="ClassIntro-title-section">
          <div className="ClassIntro-title-content">
            <h1>Cybersecurity</h1>
            <p className="ClassIntro-description">
              Step into the dynamic world of cybersecurity with our
              comprehensive stack of courses, starting with an introductory
              course that requires no prior knowledge. Designed for beginners,
              IT professionals, career changers, and students, this program
              equips you with essential skills through hands-on learning and
              real-world scenarios. Our curriculum covers network security,
              ethical hacking, threat analysis, cryptography, and incident
              response. Learn from experienced cybersecurity experts dedicated
              to your success, and gain practical experience with
              industry-standard tools and techniques. The instructors are
              experts in their field, with decades of experience. Both
              instructors have worked as cybersecurity experts for the U.S.
              government as well as for multiple large tech companies.
            </p>
          </div>
          <div className="ClassIntro-image-container">
            <img
              className="ClassIntro-img"
              src="cybersecurity.jpg"
              alt="cybersecurity"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cybersecurity;
