import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ onChange }) => {
  return (
    <div className="form-group">
      <ReCAPTCHA
        sitekey="6LewDlAqAAAAACH7VO4HIyouymiPY0u0-UNDFNHr"
        onChange={onChange}
      />
    </div>
  );
};

export default Captcha;
