// import React, { useEffect } from 'react';
import './styles/Courses.css';
import { Link } from 'react-router-dom';
import coursesInfo from '../../data/coursesInfo.json';
import ListPrice from '../ListPrice';
import DateCalculator from '../DateCalculator';
import Spline from '@splinetool/react-spline';

const TopicSection = ({ title, content }) => (
  <div>
    <li className="Course-topic-title">{title}</li>
    <ul className="Course-topics-list">
      {content.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const IntroToWebDevelopment = () => {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://js.stripe.com/v3/buy-button.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const course = coursesInfo[0].courses.find(
    (course) => course.courseName === '101 Intro to Web Development'
  );

  const common = coursesInfo[0].commonCourseData;

  const { sale, schedule, duration } = course;

  const courseDurationWeeks = parseInt(duration.split(' ')[0]);

  const scheduleDays = schedule.map((item) => item.day);
  const startDay = scheduleDays[0];
  const endDay = scheduleDays[scheduleDays.length - 1];

  const firstPeriod = DateCalculator({
    courseDuration: courseDurationWeeks,
    scheduleDays: scheduleDays,
    futureMonths: 1,
    startDay: startDay,
    endDay: endDay,
  });

  const secondPeriod = DateCalculator({
    courseDuration: courseDurationWeeks,
    scheduleDays: scheduleDays,
    futureMonths: 2,
    startDay: startDay,
    endDay: endDay,
  });

  return (
    <div className="Course-container">
      <div className="Course-top-main-content">
        <div className="spline-background-wrapper">
          <Spline
            className="spline-background"
            scene="https://prod.spline.design/nVCuZxBvl97lYUQx/scene.splinecode"
          />
          <h1 className="Course-name-overlay">{course.courseName}</h1>
        </div>

        <div className="Course-overview">
          <h2 className="Course-header">Overview</h2>
          <p className="Course-details">{course.overview}</p>
        </div>

        <div className="Course-sidebar">
          <Link to="/ApplicationForm" className="Course-apply-btn">
            Apply
          </Link>
          {/* <button
            className="Course-apply-btn"
            id="stripe-button"
            onClick={() => {
              // Open the payment link when the user clicks the button
              window.open('https://buy.stripe.com/fZe29QgtzbjEcZq3cd');
            }}
          >
            Sign up
          </button> */}
          <div className="Course-schedule">
            <p className="Course-side-details">Next Course Period :</p>
            <p className="Course-startAndEnd">
              {`${firstPeriod.startDate} - ${firstPeriod.endDate}`}
            </p>

            <p className="Course-side-details">Following Course Period:</p>
            <p className="Course-startAndEnd">
              {`${secondPeriod.startDate} - ${secondPeriod.endDate}`}
            </p>
            {schedule.map((item, index) => (
              <p key={index}>
                {item.day} {item.time}
              </p>
            ))}
          </div>
          <h3 className="Course-header-side">Average Salary</h3>
          <div>
            <a
              href="https://www.glassdoor.com/Salaries/software-developer-salary-SRCH_KO0,18.htm"
              className="Course-link"
            >
              $130,000
            </a>
          </div>
          <div>
            <h3 className="Course-header-side">Course Length</h3>
            <p className="Course-side-details">{duration}</p>
          </div>
          <div>
            <ListPrice currentPrice={sale.currentPrice} sale={sale} />
            <p className="tiny-font-blue">
              <Link to="/FinancialAid" className="Course-link">
                Financing options
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="Course-bottom-section">
        <h2 className="Course-header">Prerequisites</h2>
        <p className="Course-details">{course.prerequisites}</p>

        <section className="Course-topics-section">
          <h2 className="Course-header">Topics</h2>
          <div className="Course-details">
            {Object.entries(course.topics).map(([topicTitle, topicContent]) => (
              <TopicSection
                key={topicTitle}
                title={topicTitle}
                content={topicContent}
              />
            ))}
          </div>
        </section>

        <h2 className="Course-header">Material Requirements</h2>
        <div className="Course-details">
          <ul className="Course-topics-list">
            {common.materialRequirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
          </ul>
        </div>
        <h2 className="Course-header">Professionalism</h2>
        <p className="Course-details">{common.professionalism}</p>
        <h2 className="Course-header">Graduation Requirements</h2>
        <p className="Course-details">{common.graduationRequirements}</p>
      </div>
    </div>
  );
};

export default IntroToWebDevelopment;
