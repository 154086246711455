import { useEffect, useMemo } from 'react';

const MainJsonLd = () => {
  const mainPageData = useMemo(
    () => ({
      '@context': 'https://schema.org',
      '@type': 'EducationalOrganization',
      name: 'Ahmed Tech Academy',
      description:
        'Empowering students through expert-led coding courses and technology education. Our courses include project-based learning for real-world applications and a curriculum that reflects modern tools and technologies, including AI and security. Students have access to senior engineers for guidance and tailored interview prep with resume/LinkedIn reviews. We also provide a 50% money-back guarantee for job placement within seven months post-graduation and offer industry-recognized certifications.',
      url: 'https://www.ahmedtechacademy.com',
      logo: 'https://www.ahmedtechacademy.com/logo.png',
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        areaServed: ['US', 'Washington', 'Bellevue'],
      },
      sameAs: [
        'https://www.instagram.com/ahmedtechacademy?igsh=MWUxbHFwY2ppMjIxdg==',
        'https://www.linkedin.com/company/ahmedtechacademy',
      ],
      financialAidEligible: [
        {
          '@type': 'MonetaryGrant',
          name: 'Student Financial Aid',
          description:
            'Provides financial support to eligible students to assist with tuition fees.',
          url: 'https://www.ahmedtechacademy.com/FinancialAid',
        },
      ],

      hasCourse: [
        {
          '@type': 'EducationalOccupationalProgram',
          name: '100 Intro to Software Development',
          description:
            'Explore the fundamentals of software development and kickstart your tech career. Our course includes project-based learning and an updated curriculum reflecting modern tools and technologies. Technologies covered include HTML, CSS, and JavaScript. This program can equip you with the foundational skills to pursue careers like Software Engineering or Front-End Web Development.',
          url: 'https://www.ahmedtechacademy.com/100IntroToSoftwareDevelopment',
          keywords:
            'intro to software development, introduction to software engineering, coding bootcamp, learn to code, software development basics, programming languages, tech career',
          offers: {
            '@type': 'Offer',
            price: '265',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P2W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P2W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '101 Web Development',
          description:
            'Learn the essentials of building functional web applications and develop in-demand web development skills. The program includes project-based learning and access to senior engineers for guidance. Technologies covered include HTML, CSS, JavaScript, Git, and basic data structures and algorithms. This program prepares you for careers such as Front-End Web Developer or Software Engineer',
          url: 'https://www.ahmedtechacademy.com/101WebDevelopment',
          keywords:
            'website development, front end developer, front-end development, HTML, CSS, JavaScript, Node.js, React',
          offers: {
            '@type': 'Offer',
            price: '2600',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P4W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P4W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '201 Advanced Web Dev and APIs',
          description:
            'Enhance your web development skills with a course focused on building advanced full-stack web applications using Node.js and React.js. The curriculum includes AI integration, database design, software best practices, and interview preparation. Technologies covered include Node.js, React, MongoDB, and Express.js. This program can lead to careers such as Front-End Software Engineer or Full-Stack Software Engineer.',
          url: 'https://www.ahmedtechacademy.com/201AdvancedWebDevAPIs',
          keywords:
            'developing api, web developer api, top api, api dev, advanced web development, Node.js and React.js, full-stack development, backend APIs, API integration',
          offers: {
            '@type': 'Offer',
            price: '2600',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P12W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P12W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '202 Java Fundamentals',
          description:
            'Master Object-Oriented Programming with Java. Discover the essentials of Java syntax and delve into advanced concepts like concurrency. Gain hands-on experience with expert guidance, preparing you for the next level of Java development and tools like Spring Boot and cloud computing. This program can lead to careers such as Front-End Web Developer, Software Engineer, or Back-End Web Developer, and Full-Stack Web Developer',
          url: 'https://www.ahmedtechacademy.com/202JavaFundamentals',
          keywords:
            'java, learn java fundamentals, java developer, java development, Object-Oriented Programming, Java syntax, python',
          offers: {
            '@type': 'Offer',
            price: '2600',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P12W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P12W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '301 Expert Java Development with Spring and AWS',
          description:
            'Master Java programming with a focus on clear, best-practice code. Develop AI skills, build enterprise applications with Spring Boot and MySQL, and deploy on AWS cloud. Project-based learning, career coaching, and interview prep included.This program can lead to careers such as, Front-End Web Developer, Software Engineer, Back-End Web Developer, Full-Stack Web Developer, and Software Engineer',
          url: 'https://www.ahmedtechacademy.com/301ExpertJavaDevSpringAWS',
          keywords:
            'java development, spring, aws, ec2, aws s3, s3, amazonaws, Spring Boot, enterprise Java applications, cloud deployment, REST APIs',
          offers: {
            '@type': 'Offer',
            price: '1500',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P12W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P12W',
            },
          ],
        },

        {
          '@type': 'EducationalOccupationalProgram',
          name: '100 Intro to Cybersecurity',
          description:
            'Explore the fundamentals of cybersecurity in this 8-week course. Learn essential principles, practices, and security controls to safeguard digital assets. Understand common threats and best practices in securing systems for the digital context of today.This program prepares you for a career as a Cybersecurity Engineer.',
          url: 'https://www.ahmedtechacademy.com/100IntroToCybersecurity',
          keywords:
            'introduction to cybersecurity, cybersecurity, cybersecurity fundamentals, digital asset protection, security principles',
          offers: {
            '@type': 'Offer',
            price: '1900',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P8W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P8W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '101 Platform Security',
          description:
            "Explore the essentials of securing platforms, systems, and applications in the digital environment with the 'Platform Security' course. Covering platform hardening, data integrity, access controls, encryption, and vulnerability assessment strategies for effective digital asset protection. This program prepares you for a career as a Cybersecurity Engineer.",
          url: 'https://www.ahmedtechacademy.com/101PlatformSecurity',
          keywords:
            'application security, system security, cybersecurity essentials, information security training, digital asset protection, platform hardening, data integrity, access control, encryption, vulnerability assessment, network security training, cloud security',
          offers: {
            '@type': 'Offer',
            price: '1900',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P8W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P8W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '201 Network Security',
          description:
            'Explore essential principles and technologies to secure networks and protect digital assets from cyber threats. Covering network vulnerabilities, encryption, secure design, intrusion detection, and incident response for comprehensive network security. This program prepares you for a career as a Cybersecurity Engineer and Network Security Engineer.',
          url: 'https://www.ahmedtechacademy.com/201NetworkSecurity',
          keywords:
            'cybersecurity essentials, information security training, cyber threats, network vulnerabilities, encryption, secure network design, intrusion detection systems, firewalls, incident response, network security certification, Seattle network security course, secure network architecture, penetration testing, network and security engineer',
          offers: {
            '@type': 'Offer',
            price: '1900',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P8W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P8W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '202 Infrastructure Security',
          description:
            'Learn to secure critical infrastructure components - servers, databases, data centers - for data integrity and availability. Explore security best practices, vulnerability assessment, and incident response in infrastructure protection. This program prepares you for a career as a Cybersecurity Engineer and Network Security Engineer.',
          url: 'https://www.ahmedtechacademy.com/202InfrastructureSecurity',
          keywords:
            'data security, server security, database security, information security engineer, data center security, cybersecurity essentials, critical infrastructure protection, data integrity, access control, disaster recovery, cloud network security, cloud infrastructure security, IT security training, security compliance',
          offers: {
            '@type': 'Offer',
            price: '1900',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P8W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P8W',
            },
          ],
        },
        {
          '@type': 'EducationalOccupationalProgram',
          name: '301 Threat and Intrusion Detection',
          description:
            'Master essential skills in identifying, analyzing, and responding to cybersecurity threats and intrusions. Learn threat detection, intrusion prevention, and security incident response for safeguarding systems and networks.This program prepares you for a career as a Cybersecurity Engineer.',
          url: 'https://www.ahmedtechacademy.com/301ThreatAndIntrusionDetection',
          keywords:
            'cybersecurity threats, intrusion detection, security incident response, network security, IDS, SIEM, threat detection methodologies, IPS, log analysis, security tools, threat intelligence, security analyst bootcamp, SOC analyst training, penetration testing, aws intrusion detection, cyber threat hunting',
          offers: {
            '@type': 'Offer',
            price: '1900',
            priceCurrency: 'USD',
          },
          hasCourseInstance: [
            {
              '@type': 'CourseInstance',
              courseMode: 'In-Person',
              duration: 'P8W',
            },
            {
              '@type': 'CourseInstance',
              courseMode: 'Online',
              duration: 'P8W',
            },
          ],
        },
      ],
    }),
    []
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(mainPageData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [mainPageData]);

  return null;
};

export default MainJsonLd;
