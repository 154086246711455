import './styles/Courses.css';
import { Link } from 'react-router-dom';
import coursesInfo from '../../data/coursesInfo.json';
import ListPrice from '../ListPrice';
import DateCalculator from '../DateCalculator';
import Spline from '@splinetool/react-spline';

const TopicSection = ({ title, content }) => (
  <div>
    <li className="Course-topic-title">{title}</li>
    <ul className="Course-topics-list">
      {content.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const NetworkSecurity = () => {
  const course = coursesInfo[0].courses.find(
    (course) => course.courseName === '201 Network Security'
  );

  const common = coursesInfo[0].commonCourseData;

  const { sale, prerequisites, links, duration, schedule } = course;

  const courseDurationWeeks = parseInt(duration.split(' ')[0]);

  const hasAvailableNextQuarter = schedule.some(
    (item) => item.day === 'Available next quarter'
  );

  let firstPeriod = null;
  let secondPeriod = null;

  if (!hasAvailableNextQuarter) {
    const scheduleDays = schedule.map((item) => item.day);
    const startDay = scheduleDays[0];
    const endDay = scheduleDays[scheduleDays.length - 1];

    firstPeriod = DateCalculator({
      courseDuration: courseDurationWeeks,
      futureMonths: 1,
      startDay: startDay,
      endDay: endDay,
    });

    secondPeriod = DateCalculator({
      courseDuration: courseDurationWeeks,
      futureMonths: 2,
      startDay: startDay,
      endDay: endDay,
    });
  }

  const renderPrerequisites = (part, index) => {
    if (part.startsWith('[') && part.endsWith(']')) {
      const key = part.slice(1, -1);
      return (
        <Link key={index} to={links[key]}>
          {key.replace(/([A-Z])/g, ' $1').trim()}
        </Link>
      );
    }
    return part;
  };

  return (
    <div className="Course-container">
      <div>
        <div className="Course-top-main-content">
          <div className="spline-background-wrapper">
            <Spline
              className="spline-background"
              scene="https://prod.spline.design/nVCuZxBvl97lYUQx/scene.splinecode"
            />
            <h1 className="Course-name-overlay">{course.courseName}</h1>
          </div>

          <div className="Course-overview">
            <h2 className="Course-header">Overview</h2>
            <p className="Course-details">{course.overview}</p>
          </div>

          <div className="Course-sidebar">
            <Link to="/ApplicationForm" className="Course-apply-btn">
              Apply
            </Link>
            {/* <button
          className="Course-apply-btn disabled"
          id="stripe-button"
          onClick={(e) => {
            e.preventDefault();
            // Open the payment link when the user clicks the button
            // window.open('https://buy.stripe.com/7sI8ye9171J41gI7su');
          }}
        >
          Sign up
        </button> */}
            <div className="Course-schedule">
              {hasAvailableNextQuarter ? (
                <>
                  <p className="Course-side-details">Next Course Period:</p>
                  <p className="Course-startAndEnd">Available next quarter</p>
                </>
              ) : (
                <>
                  <p className="Course-side-details">Next Course Period:</p>
                  <p className="Course-startAndEnd">
                    {`${firstPeriod?.startDate} - ${firstPeriod?.endDate}`}
                  </p>

                  <p className="Course-side-details">
                    Following Course Period:
                  </p>
                  <p className="Course-startAndEnd">
                    {`${secondPeriod?.startDate} - ${secondPeriod?.endDate}`}
                  </p>
                </>
              )}
              {schedule.map((item, index) =>
                item.day !== 'Available next quarter' ? (
                  <p key={index}>
                    {item.day} {item.time}
                  </p>
                ) : null
              )}
            </div>
            <h3 className="Course-header-side">Average Salary</h3>
            <div>
              <a
                href="https://www.glassdoor.com/Salaries/security-engineer-salary-SRCH_KO0,17.htm"
                className="Course-link"
              >
                $191,000
              </a>
            </div>
            <div>
              <h3 className="Course-header-side">Course Length</h3>
              <p className="Course-side-details">{course.duration}</p>
            </div>
            <div>
              <ListPrice currentPrice={sale.currentPrice} sale={sale} />
              <p className="tiny-font-blue">
                <Link to="/FinancialAid" className="Course-link">
                  Financing options
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="Course-bottom-section">
          <h2 className="Course-header">Prerequisites</h2>
          <p className="Course-details">
            {prerequisites
              .split(/(\[.*?\])/g)
              .map((part, index) => renderPrerequisites(part, index))}
          </p>

          <section className="Course-topics-section">
            <h2 className="Course-header">Topics</h2>
            <div className="Course-details">
              {Object.entries(course.topics).map(
                ([topicTitle, topicContent]) => (
                  <TopicSection
                    key={topicTitle}
                    title={topicTitle}
                    content={topicContent}
                  />
                )
              )}
            </div>
          </section>

          <h2 className="Course-header">Material Requirements</h2>
          <div className="Course-details">
            <ul className="Course-topics-list">
              {common.materialRequirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))}
            </ul>
          </div>

          <h2 className="Course-header">Professionalism</h2>
          <p className="Course-details">{common.professionalism}</p>
          <h2 className="Course-header">Graduation Requirements</h2>
          <p className="Course-details">{common.graduationRequirements}</p>
        </div>
      </div>
    </div>
  );
};

export default NetworkSecurity;
