import './App.css';
//import '@stripe/stripe-js';
import { Routes, Route } from 'react-router-dom';
import Nav from './Components/Nav';
import Main from './Components/Main';
import SE101IntroToWebDevelopment from './Components/Courses/101IntroToWebDevelopment';
import SE202JavaFundamentals from './Components/Courses/202JavaFundamentals';
import FinancialAid from './Components/Pages/FinancialAid';
import SE201WebDevAndJSFoundations from './Components/Courses/201WebDevAndJSFoundations';
import SE302AlgorithmsDataStructures from './Components/Courses/302AlgorithmsDataStructures';
import SE301IntermJSandWebDev from './Components/Courses/301IntermJSandWebDev';
import SE401ExpertWebDevelopment from './Components/Courses/401ExpertWebDevelopment';
import SE301ExpertJavaDevSpringAWS from './Components/Courses/301ExpertJavaDevSpringAWS';
import SE100IntroToCybersecurity from './Components/Courses/100IntroToCybersecurity';
import SE101PlatformSecurity from './Components/Courses/101PlatformSecurity';
import SE201NetworkSecurity from './Components/Courses/201NetworkSecurity';
import SE202InfrastructureSecurity from './Components/Courses/202InfrastructureSecurity';
import SE301ThreatAndIntrusionDetection from './Components/Courses/301ThreatAndIntrusionDetection';
import SE401AIandMachineLearning from './Components/Courses/401AIandMachineLearning';
import FAQ from './Components/Pages/FAQ';
import ContactUs from './Components/Pages/ContactUs';
import CourseCategories from './Components/Pages/CourseCategories';
import SoftwareEngineeringCourses from './Components/Pages/SoftwareEngineeringCourses';
import CybersecurityEngineeringCourses from './Components/Pages/CybersecurityEngineeringCourses';
import CareerPaths from './Components/Pages/CareerPaths';
import FreeConsultation from './Components/Pages/FreeConsultation';
import ApplicationForm from './Components/Pages/ApplicationForm';
import Footer from './Components/Footer';
import TutoringServices from './Components/Pages/TutoringServices';
import TutorRequestForm from './Components/Pages/TutorRequestForm';
import SelfPacedLearning from './Components/Pages/SelfPacedLearning';
import MainJsonLd from './Components/MainJsonLd';
import WebDevelopment from './Components/Pages/WebDevelopment';
import Cybersecurity from './Components/Pages/Cybersecurity';
import Java from './Components/Pages/Java';
import ContinuingEducation from './Components/Pages/ContinuingEducation';
import Blog from './Components/Pages/Blog';
import BlogPost from './Components/Pages/BlogPost';
import NotFound from './Components/NotFound';
import Assignment from './Components/Pages/Assignment';
import AssignmentPost from './Components/Pages/AssignmentPost';

function App() {
  return (
    <div className="App">
      <Nav />
      <MainJsonLd />
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/courses/" element={<CourseCategories />} />
          <Route
            path="/courses/software-engineering"
            element={<SoftwareEngineeringCourses />}
          />
          <Route
            path="/courses/cybersecurity-engineering"
            element={<CybersecurityEngineeringCourses />}
          />
          <Route path="/career-paths" element={<CareerPaths />} />

          <Route
            path="/101IntroToWebDevelopment"
            element={<SE101IntroToWebDevelopment />}
          />

          <Route
            path="/202JavaFundamentals"
            element={<SE202JavaFundamentals />}
          />
          <Route
            path="/301ExpertJavaDevSpringAWS"
            element={<SE301ExpertJavaDevSpringAWS />}
          />
          <Route
            path="/201WebDevAndJSFoundations"
            element={<SE201WebDevAndJSFoundations />}
          />
          <Route
            path="/302AlgorithmsDataStructures"
            element={<SE302AlgorithmsDataStructures />}
          />
          <Route
            path="/301IntermJSandWebDev"
            element={<SE301IntermJSandWebDev />}
          />
          <Route
            path="/401ExpertWebDevelopment"
            element={<SE401ExpertWebDevelopment />}
          />
          <Route
            path="/100IntroToCybersecurity"
            element={<SE100IntroToCybersecurity />}
          />
          <Route
            path="/101PlatformSecurity"
            element={<SE101PlatformSecurity />}
          />
          <Route
            path="/201NetworkSecurity"
            element={<SE201NetworkSecurity />}
          />
          <Route
            path="/202InfrastructureSecurity"
            element={<SE202InfrastructureSecurity />}
          />
          <Route
            path="/301ThreatAndIntrusionDetection"
            element={<SE301ThreatAndIntrusionDetection />}
          />
          <Route
            path="/401AIandMachineLearning"
            element={<SE401AIandMachineLearning />}
          />
          <Route path="/FinancialAid" element={<FinancialAid />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Faq" element={<FAQ />} />
          <Route path="/FreeConsultation" element={<FreeConsultation />} />
          <Route path="/ApplicationForm" element={<ApplicationForm />} />
          <Route path="/TutoringServices" element={<TutoringServices />} />
          <Route path="/TutorRequestForm" element={<TutorRequestForm />} />
          <Route path="/SelfPacedLearning" element={<SelfPacedLearning />} />
          <Route path="/WebDevelopment" element={<WebDevelopment />} />
          <Route path="/Cybersecurity" element={<Cybersecurity />} />
          <Route path="/Java" element={<Java />} />
          <Route
            path="/ContinuingEducation"
            element={<ContinuingEducation />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />

          <Route path="/assignments" element={<Assignment />} />
          <Route path="/assignments/:id" element={<AssignmentPost />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
