import React, { useEffect, useState } from 'react';
import faqData from '../../data/faq.json';
import './styles/Faq.css';

const Faq = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(faqData);
  }, []);

  const [accordionItems, setAccordionItems] = useState([]);

  useEffect(() => {
    if (data) {
      setAccordionItems([
        ...data.map((item) => ({
          ...item,
          toggled: false,
        })),
      ]);
    }
  }, [data]);

  const handleAccordionToggle = (clickedItem) => {
    setAccordionItems((prevItems) =>
      prevItems.map((item) =>
        item.id === clickedItem.id
          ? { ...item, toggled: !item.toggled }
          : { ...item, toggled: false }
      )
    );
  };

  return (
    <div className="faq-container">
      <div className="faq-title-section">
        <h1>Frequently Asked Questions</h1>
      </div>

      <div className="accordion-section">
        {accordionItems.map((listItem, key) => (
          <div
            className={`accordion ${listItem.toggled ? 'toggled' : ''}`}
            key={key}
          >
            <button
              className="toggle"
              onClick={() => handleAccordionToggle(listItem)}
            >
              <p>{listItem.label}</p>
              <div className="direction-indicator">
                {listItem.toggled ? '-' : '+'}
              </div>
            </button>
            <div className="content-parent">
              <div className="content">
                {listItem.content.map((contentItem, index) => {
                  switch (contentItem.type) {
                    case 'paragraph':
                      return <p key={index}>{contentItem.text}</p>;
                    case 'list':
                      return (
                        <ol key={index}>
                          {contentItem.items.map((listItem, listIndex) => (
                            <li key={listIndex}>{listItem}</li>
                          ))}
                        </ol>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
