import { useEffect, useState } from 'react';
import './Main.css';
import { Link } from 'react-router-dom';
import FillsGap from './FillsGap';
import { TECH_DATA } from './TECH_DATA';
import TechCard from './TechCard';
import Spline from '@splinetool/react-spline';

const Main = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page-container">
      <div className="main-img-container">
        <div className="main-classroomImg-container"></div>

        <div className="main-text-section">
          <h1>Ahmed Tech Academy</h1>
          <p>
            Get a tech job with expert-led coding courses designed with hiring
            managers to equip you with in-demand skills.
          </p>
          <div className="link-btn-container">
            <Link to="/courses" className="button">
              Courses
            </Link>
            <Link to="/career-paths" className="button">
              Career Paths
            </Link>
            <Link to="/FreeConsultation" className="button">
              Request a Free Consultation
            </Link>
            <Link to="/TutoringServices" className="button">
              Private Tutor
            </Link>
          </div>
          {isLargeScreen && (
            <div className="spline-robot-wrapper">
              <Spline
                className="ATA-robot"
                scene="https://prod.spline.design/6DenbiQmBrmvqkTF/scene.splinecode"
              />
            </div>
          )}
        </div>
      </div>

      <main>
        <FillsGap />
        <section className="tech-data-section">
          <h1 className="main-title">Technologies we cover</h1>
          {TECH_DATA.map((tech) => (
            <TechCard key={tech.id} tech={tech} />
          ))}
        </section>
      </main>
    </div>
  );
};

export default Main;
