import React, { useCallback, useEffect, useState } from 'react';
import './styles/TutorRequestForm.css';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import environmentVariables from '../EnvironmentVariables.json';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Stack, TextField } from '@mui/material';
import Captcha from './Captcha';

const TutorRequestForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [interest, setInterest] = useState('');
  const [yourGoal, setYourGoal] = useState('');
  const [learned, setLearned] = useState('');
  const [subject, setSubject] = useState([]);
  const [attendance, setAttendance] = useState('');
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [thirdDate, setThirdDate] = useState(null);
  const [firstStartTime, setFirstStartTime] = useState(null);
  const [firstEndTime, setFirstEndTime] = useState(null);
  const [secondStartTime, setSecondStartTime] = useState(null);
  const [secondEndTime, setSecondEndTime] = useState(null);
  const [thirdStartTime, setThirdStartTime] = useState(null);
  const [thirdEndTime, setThirdEndTime] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const navigate = useNavigate();

  const handleSubjectChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSubject((prevSub) => [...prevSub, value]);
    } else {
      setSubject((prevSub) => prevSub.filter((sub) => sub !== value));
    }
  };

  const formatDateTime = (date, startTime, endTime) => {
    return `${date ? date.format('YYYY-MM-DD') : ''} from ${
      startTime ? startTime.format('HH:mm') : ''
    } to ${endTime ? endTime.format('HH:mm') : ''}`;
  };

  const firstAvailability = formatDateTime(
    firstDate,
    firstStartTime,
    firstEndTime
  );
  const secondAvailability = formatDateTime(
    secondDate,
    secondStartTime,
    secondEndTime
  );
  const thirdAvailability = formatDateTime(
    thirdDate,
    thirdStartTime,
    thirdEndTime
  );

  const handleCaptchaChange = useCallback((value) => {
    setCaptchaValue(value);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert('Please complete the CAPTCHA before submitting.');
      return;
    }

    const serviceId = environmentVariables.secondAccount.serviceId;
    const templateId = environmentVariables['tutor-request-templateId'];
    const publicKey = environmentVariables.secondAccount.publicKey;

    const templateParams = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      to_name: 'Farhad',
      interest: interest,
      your_goal: yourGoal,
      learned: learned,
      subjects: subject.join(', '),
      attendance: attendance,
      availability: `${firstAvailability}, ${secondAvailability}, ${thirdAvailability}`,
    };

    console.log('Form Data being sent:', templateParams);

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        // Clear form fields
        setFirstName('');
        setLastName('');
        setEmail('');
        setInterest('');
        setYourGoal('');
        setLearned('');
        setSubject([]);
        setAttendance('');
        setFirstDate(null);
        setSecondDate(null);
        setThirdDate(null);
        setFirstStartTime(null);
        setFirstEndTime(null);
        setSecondStartTime(null);
        setSecondEndTime(null);
        setThirdStartTime(null);
        setThirdEndTime(null);

        navigate('/');
      });
  };

  return (
    <div className="tutorRequest-container">
      <h1>Tutor Request</h1>

      <form onSubmit={handleSubmit}>
        <div className="tutorReq-form-group">
          <label htmlFor="first_name">First Name *</label>
          <input
            type="text"
            id="first_name"
            className="tutorReq-input"
            name="first_name"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="last_name">Last Name *</label>
          <input
            type="text"
            id="last_name"
            className="tutorReq-input"
            name="last_name"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            className="tutorReq-input"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="interest">
            What would you like to learn? Do you have specific languages or
            frameworks in mind? Or do you want to gain general software
            knowledge? *
          </label>
          <textarea
            name="interest"
            id="interest"
            className="tutorReq-textarea"
            value={interest}
            onChange={(e) => setInterest(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="your_goal">What is your overarching goal? *</label>
          <input
            type="text"
            id="your_goal"
            className="tutorReq-input"
            name="your_goal"
            value={yourGoal}
            onChange={(e) => setYourGoal(e.target.value)}
            required
          />
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="learned">
            Where are you and your learning process? What have you learned so
            far? *
          </label>
          <textarea
            name="learned"
            id="learned"
            className="tutorReq-textarea"
            value={learned}
            onChange={(e) => setLearned(e.target.value)}
            required
          />
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="subject">
            What subjects are you interested in? Select all that apply: *
          </label>
          <div className="subject-checkbox-group">
            <div className="subject-checkbox-item">
              <input
                type="checkbox"
                id="subject-JavaScript"
                name="subject"
                value="JavaScript"
                onChange={handleSubjectChange}
              />
              <label htmlFor="subject-JavaScript">JavaScript</label>
            </div>
            <div className="subject-checkbox-item">
              <input
                type="checkbox"
                id="subject-Java"
                name="subject"
                value="Java"
                onChange={handleSubjectChange}
              />
              <label htmlFor="subject-Java">Java</label>
            </div>
            <div className="subject-checkbox-item">
              <input
                type="checkbox"
                id="subject-Python"
                name="subject"
                value="Python"
                onChange={handleSubjectChange}
              />
              <label htmlFor="subject-Python">Python</label>
            </div>
            <div className="subject-checkbox-item">
              <input
                type="checkbox"
                id="subject-WebDevelopment"
                name="subject"
                value="WebDevelopment"
                onChange={handleSubjectChange}
              />
              <label htmlFor="subject-WebDevelopment">Web Development</label>
            </div>
            <div className="subject-checkbox-item">
              <input
                type="checkbox"
                id="subject-AlgorithmsAndData"
                name="subject"
                value="AlgorithmsAndData"
                onChange={handleSubjectChange}
              />
              <label htmlFor="subject-AlgorithmsAndData">
                Algorithms and Data Structures
              </label>
            </div>
            <div className="subject-checkbox-item">
              <input
                type="checkbox"
                id="subject-other"
                name="subject"
                value="other"
                onChange={handleSubjectChange}
              />
              <label htmlFor="subject-other">Other</label>
            </div>
          </div>
        </div>
        <div className="tutorReq-form-group">
          <label htmlFor="attendance">How would you like to attend? *</label>
          <select
            id="attendance"
            name="attendance"
            className="tutorReq-input"
            value={attendance}
            onChange={(e) => setAttendance(e.target.value)}
            required
          >
            <option disabled value="">
              ---Select your attendance option
            </option>
            <option value="online">Online</option>
            <option value="in-person-bellevue">in-person in Bellevue</option>
            <option value="in-person-issaquah">in-person in Issaquah</option>
          </select>
        </div>
        <div className="tutorReq-form-group">
          <label>
            Select three days and times you’re available for your first lesson.
            *
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
              <DatePicker
                label="First Date"
                value={firstDate}
                onChange={(newValue) => setFirstDate(newValue)}
                textField={(params) => (
                  <TextField {...params} className="tutorReq-input" />
                )}
                required
              />
              <Stack direction="row" spacing={2}>
                <TimePicker
                  label="From"
                  value={firstStartTime}
                  onChange={(newValue) => setFirstStartTime(newValue)}
                  textField={(params) => (
                    <TextField
                      {...params}
                      className="tutorReq-input timePicker"
                    />
                  )}
                  required
                />
                <TimePicker
                  label="To"
                  value={firstEndTime}
                  onChange={(newValue) => setFirstEndTime(newValue)}
                  textField={(params) => (
                    <TextField
                      {...params}
                      className="tutorReq-input timePicker"
                    />
                  )}
                  required
                />
              </Stack>
              <DatePicker
                label="Second Date"
                value={secondDate}
                onChange={(newValue) => setSecondDate(newValue)}
                textField={(params) => (
                  <TextField {...params} className="tutorReq-input" />
                )}
                required
              />
              <Stack direction="row" spacing={2}>
                <TimePicker
                  label="From"
                  value={secondStartTime}
                  onChange={(newValue) => setSecondStartTime(newValue)}
                  textField={(params) => <TextField {...params} />}
                  required
                />
                <TimePicker
                  label="To"
                  value={secondEndTime}
                  onChange={(newValue) => setSecondEndTime(newValue)}
                  textField={(params) => <TextField {...params} />}
                  required
                />
              </Stack>
              <DatePicker
                label="Third Date"
                value={thirdDate}
                onChange={(newValue) => setThirdDate(newValue)}
                textField={(params) => (
                  <TextField {...params} className="tutorReq-input" />
                )}
                required
              />
              <Stack direction="row" spacing={2}>
                <TimePicker
                  label="From"
                  value={thirdStartTime}
                  onChange={(newValue) => setThirdStartTime(newValue)}
                  textField={(params) => (
                    <TextField
                      {...params}
                      className="tutorReq-input timePicker"
                    />
                  )}
                  required
                />
                <TimePicker
                  label="To"
                  value={thirdEndTime}
                  onChange={(newValue) => setThirdEndTime(newValue)}
                  textField={(params) => (
                    <TextField
                      {...params}
                      className="tutorReq-input timePicker"
                    />
                  )}
                  required
                />
              </Stack>
            </Stack>
          </LocalizationProvider>
        </div>
        <div className="tutorReq-captcha">
          <Captcha onChange={handleCaptchaChange} />
        </div>
        <button type="submit" className="tutorReq-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default TutorRequestForm;
