import React, { useEffect, useState } from 'react';
import postData from '../../data/assignments.json';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './styles/Blog.css';

const Assignment = () => {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setPosts(postData);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1
      ) {
        if (visiblePosts < posts.length) {
          console.log('Loading more posts...');
          setVisiblePosts((prev) => Math.min(prev + 6, posts.length));
        } else {
          setHasMorePosts(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [posts, visiblePosts]);

  const getExcerpt = (content) => {
    let text = '';
    for (let section of content) {
      if (section.type === 'paragraph') {
        text += section.content + ' ';
      }
    }
    const words = text.split(' ');
    return words.slice(0, 25).join(' ') + '...';
  };

  return (
    <HelmetProvider>
      <div className="blog-container">
        <Helmet>
          <title>
            Stay Ahead with the Latest Tech Insights at Ahmed Tech Academy
          </title>
        </Helmet>
        <h1 className="blogPage-header">
          ASSIGNMENTS
        </h1>
        <p className="blogPage-subtitle">
          
        </p>
        <div className="blog-scrollable">
          <div className="blog-list">
            {posts.slice(0, visiblePosts).map((post) => (
              <div
                key={post.id}
                className="blog-rectangle"
                onClick={() => navigate(`/assignments/${post.id}`)}
              >
                <img src={post.image} alt={post.title} className="blog-image" />
                <div className="blog-content">
                  <h2 className="blog-title">{post.title}</h2>
                  <p className="blog-author">{post.author}</p>
                  <p className="blog-date">{post.publishDate}</p>
                  <p className="blog-excerpt">{getExcerpt(post.content)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {!hasMorePosts && (
          <p className="blog-endPosts">You've seen all the available posts.</p>
        )}
      </div>
    </HelmetProvider>
  );
};

export default Assignment;
