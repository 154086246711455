import React from 'react';
import './styles/ClassesIntroPages.css';
import { Link } from 'react-router-dom';

const ContinuingEducation = () => {
  return (
    <div className="ClassIntro-background">
      <div className="ClassIntro-container">
        <div className="ClassIntro-title-section">
          <div className="ClassIntro-title-content">
            <h1>Continuing Education</h1>
            <p className="ClassIntro-description">
              These courses are for new engineers who want to advance specific
              skills. We currently offer two courses:{' '}
              <Link to="/AlgorithmsDataStructures" className="Course-req-link">
                302 Algorithms and Data Structures
              </Link>{' '}
              as well as{' '}
              <Link to="/AIandMachineLearning" className="Course-req-link">
                401 AI and Machine Learning.
              </Link>{' '}
            </p>
          </div>
          <div className="ClassIntro-image-container">
            <img
              className="ClassIntro-img"
              src="continuingEducation.jpg"
              alt="continuingEducation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinuingEducation;
