import React from 'react';
import { Link } from 'react-router-dom';
import './styles/CourseCategories.css';

const CourseCategories = () => {
  return (
    <div className="course-categories-container">
      <div className="categories">
        <Link to="/courses/software-engineering" className="category-link">
          Software Engineering
        </Link>
        <Link to="/courses/cybersecurity-engineering" className="category-link">
          Cybersecurity Engineering
        </Link>
      </div>
    </div>
  );
};

export default CourseCategories;
