import React from 'react';

const ListPrice = ({
  currentPrice = 0,
  sale = { isOnSale: false, salePercentage: 0 },
}) => {
  const { isOnSale, salePercentage } = sale;

  const discountedPrice = isOnSale
    ? (currentPrice - currentPrice * (salePercentage / 100)).toFixed(2)
    : currentPrice.toFixed(2);

  return (
    <div>
      <h3 className="Course-header-side">Price</h3>
      {isOnSale ? (
        <p className="Course-side-details">
          <span
            style={{
              textDecoration: 'line-through',
              paddingRight: '0.5em',
            }}
          >
            ${currentPrice.toFixed(2)}
          </span>
          <span style={{ color: 'red' }}> -{salePercentage}%</span>
          <strong style={{ marginLeft: '10px', color: 'blue' }}>
            ${discountedPrice}
          </strong>
        </p>
      ) : (
        <p className="Course-side-details">
          <strong style={{ color: 'blue' }}>${currentPrice.toFixed(2)}</strong>
        </p>
      )}
      {isOnSale && (
        <p
          className="tiny-font"
          style={{
            color: 'white',
            backgroundColor: 'red',
            display: 'inline-block',
            padding: '0.2em',
            borderRadius: '5px',
            marginBottom: '0.5em',
          }}
        >
          Deal starts: {sale.saleStartDate}　 Deal ends: {sale.saleEndDate}　
        </p>
      )}
    </div>
  );
};

export default ListPrice;
