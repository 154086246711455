const DateCalculator = ({
  courseDuration,
  futureMonths = 1,
  startDay,
  endDay,
}) => {
  const findNextSpecifiedDay = (date, dayName) => {
    const dayIndex = getWeekdayIndex(dayName);
    const resultDate = new Date(date);
    let iterations = 0; // Safety limit
    while (resultDate.getDay() !== dayIndex && iterations < 7) {
      resultDate.setDate(resultDate.getDate() + 1);
      iterations++;
    }
    return resultDate;
  };

  const findLastSpecifiedDay = (date, dayName) => {
    const dayIndex = getWeekdayIndex(dayName);
    const resultDate = new Date(date);
    let iterations = 0; // Safety limit
    while (resultDate.getDay() !== dayIndex && iterations < 7) {
      resultDate.setDate(resultDate.getDate() - 1);
      iterations++;
    }
    return resultDate;
  };

  const getWeekdayIndex = (dayName) => {
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return weekdays.indexOf(dayName);
  };

  const durationInWeeks = parseInt(courseDuration, 10) || 0;
  const durationInDays = durationInWeeks * 7;

  const today = new Date();
  const startMonth = (today.getMonth() + futureMonths) % 12;
  const startYear =
    today.getMonth() + futureMonths >= 12
      ? today.getFullYear() + 1
      : today.getFullYear();
  const startDateCandidate = new Date(startYear, startMonth, 1);

  const firstStartDate = findNextSpecifiedDay(startDateCandidate, startDay);
  if (!firstStartDate) return { startDate: '', endDate: '' };

  const endDateCandidate = new Date(firstStartDate);
  endDateCandidate.setDate(firstStartDate.getDate() + durationInDays - 1);
  const firstEndDate = findLastSpecifiedDay(endDateCandidate, endDay);

  const formatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return {
    startDate: firstStartDate.toLocaleDateString('en-US', formatOptions),
    endDate: firstEndDate.toLocaleDateString('en-US', formatOptions),
  };
};

export default DateCalculator;
