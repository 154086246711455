import React from 'react';
import './TechCard.css';

const TechCard = ({ tech }) => {
  return (
    <div className="tech-card">
      <img src={tech.image} alt={tech.image} className="tech-card-image" />
      <h3 className="tech-card-title">{tech.name}</h3>
      <p className="tech-card-description">{tech.description}</p>
    </div>
  );
};

export default TechCard;
