import React from 'react';
import { Link } from 'react-router-dom';
import './styles/CategoryBoxes.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import coursesInfo from '../../data/coursesInfo.json';
import DateCalculator from '../DateCalculator';

const CybersecurityEngineeringCourses = () => {
  const cybersecurityEngineeringCourses = coursesInfo[0].courses.filter(
    (course) => course.category === 'cybersecurity engineering'
  );

  const courseLinkMap = {
    '100 Intro to Cybersecurity': '/100IntroToCybersecurity',
    '101 Platform Security': '/101PlatformSecurity',
    '201 Network Security': '/201NetworkSecurity',
    '202 Infrastructure Security': '/202InfrastructureSecurity',
    '301 Threat and Intrusion Detection': '/301ThreatAndIntrusionDetection',
  };

  return (
    <div className="category-box-container">
      <h1>Cybersecurity Engineering Courses</h1>
      <div className="course-category">
        {cybersecurityEngineeringCourses.map((course) => {
          const schedule = Array.isArray(course.schedule)
            ? course.schedule
            : [];

          const hasAvailableNextQuarter = schedule.some(
            (item) => item.day === 'Available next quarter'
          );

          let startDate = 'Schedule to be announced';
          let endDate = '';

          if (!hasAvailableNextQuarter) {
            const {
              startDate: calculatedStartDate,
              endDate: calculatedEndDate,
            } = DateCalculator({
              courseDuration: parseInt(course.duration.split(' ')[0], 10),
              futureMonths: 1,
              startDay: schedule[0]?.day,
              endDay: schedule[schedule.length - 1]?.day,
            });
            startDate = calculatedStartDate;
            endDate = calculatedEndDate;
          }

          return (
            <div key={course.id} className="course-box">
              <Link
                to={courseLinkMap[course.courseName]}
                className="course-box-link"
              >
                <p className="course-name">{course.courseName}</p>
                <p className="course-delivery">{course.delivery}</p>
                <p className="course-startAndEnd">
                  {hasAvailableNextQuarter
                    ? 'Available next quarter'
                    : `${startDate} - ${endDate}`}
                </p>
                <p className="course-schedule">
                  {schedule.length > 0
                    ? schedule.map(
                        (item, index) =>
                          item.day !== 'Available next quarter' && (
                            <span key={index}>
                              {item.day} {item.time}
                              {index < schedule.length - 1 && ', '}
                            </span>
                          )
                      )
                    : 'No schedule available'}
                </p>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-icon"
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CybersecurityEngineeringCourses;
