import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-title-box">
        <div className="not-found-404">404</div>
        <div className="not-found-text">
          ERROR
          <p className="not-found-small-text">
            SORRY, THE PAGE NOT FOUND
            <br></br>BACK TO
            <Link to="/"> HOMEPAGE</Link>
          </p>
        </div>
      </div>
      <div className="not-found-bot-container">
        <img
          src="/ATAbot-404.png"
          alt="404 error bot"
          className="not-found-bot-image"
        />
      </div>
    </div>
  );
};

export default NotFound;
