import React from 'react';
import './styles/ClassesIntroPages.css';

const Java = () => {
  return (
    <div className="ClassIntro-background">
      <div className="ClassIntro-container">
        <div className="ClassIntro-title-section">
          <div className="ClassIntro-title-content">
            <h1>Java</h1>
            <p className="ClassIntro-description">
              Perfect for students interested in back-end engineering, our Java
              courses is the path to becoming an expert back-end engineer with
              advanced knowledge of Java, the Spring Boot framework, Cloud
              Computing with AWS, and will also show you how to use modern AI
              tools to enhance your skills and build software efficiently. As of
              2024, this is a necessary skill for all software engineers.
            </p>
          </div>
          <div className="ClassIntro-image-container">
            <img className="ClassIntro-img" src="java.jpg" alt="java" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Java;
