import React from 'react';
import postData from '../../data/blogPost.json';
import { useParams } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './styles/BlogPost.css';

const BlogPost = () => {
  const { id } = useParams();
  const post = postData.find((post) => post.id === parseInt(id));

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <HelmetProvider>
      <div className="blog-post-container">
        <Helmet>
          <title>{post.title} - Ahmed Tech Academy</title>
        </Helmet>
        <h1 className="blog-post-title">{post.title}</h1>
        <div className="blog-post-publisher">
          <p>{post.author}</p>
          <p>{post.publishDate}</p>
        </div>
        <img src={post.image} alt={post.title} className="blog-post-image" />
        <div className="blog-post-content">
          {post.content.map((section, index) => {
            switch (section.type) {
              case 'heading':
                return (
                  <h2 key={index} className="blog-post-heading">
                    {section.content}
                  </h2>
                );
              case 'paragraph':
                return (
                  <p key={index} className="blog-post-paragraph">
                    {section.content}
                  </p>
                );
              case 'bulletPoint':
                return (
                  <ul key={index} className="blog-post-list">
                    {section.content.map((point, subIndex) => (
                      <li key={subIndex}>{point}</li>
                    ))}
                  </ul>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default BlogPost;
