import React from 'react';
import './styles/FinancialAid.css';

const FinancialAid = () => {
  return (
    <div className="financialAid-container">
      <div className="container-finance">
        <div className="top-page-content">
          <div className="title-content">
            <h1>Invest in Your Tech Career:</h1>
            <h1>Financial Aid Solutions at</h1>
            <h1 className="title-blue">Ahmed Tech Academy</h1>
          </div>

          <div className="image-container">
            <img
              className="classroom-img"
              src="FinancialAidImage.jpg"
              alt="students in classroom"
            />
          </div>
        </div>
        <div className="finance-content">
          <p>
            At Ahmed Tech Academy, we believe that everyone deserves the
            opportunity to pursue a rewarding career in tech, regardless of
            their financial situation. To support this mission, we offer
            comprehensive financial aid and scholarship programs designed to
            make our courses accessible to all motivated learners. Our financial
            aid options include need-based scholarships, flexible payment plans,
            and grants that cover a significant portion of tuition costs. By
            reducing financial barriers, we aim to empower individuals from
            diverse backgrounds to transform their lives and achieve their
            career goals in the tech industry.
          </p>
          <br></br>
          <p>
            Our commitment to affordability extends beyond just financial
            assistance; we also provide personalized support throughout the
            application process to help you identify and apply for the best
            financial aid options available. Whether you are looking to upskill
            for a new career or enhance your existing tech knowledge, our
            financial aid programs ensure that your financial circumstances do
            not stand in the way of your success. Join us at Ahmed Tech Academy,
            where your dreams and aspirations are supported every step of the
            way.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FinancialAid;
