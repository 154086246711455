import React from 'react';
import './Footer.css';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <div className="icons">
        <a href="https://www.linkedin.com/company/ahmedtechacademy">
          <FaLinkedin className="footer-icons" />
        </a>
        <a href="https://www.instagram.com/ahmedtechacademy?igsh=MWUxbHFwY2ppMjIxdg==">
          <FaInstagram className="footer-icons" />
        </a>
      </div>
      <div className="copy-right"> &copy; Ahmed Tech Academy</div>
    </footer>
  );
};

export default Footer;
