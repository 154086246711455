import React from 'react';
import './styles/SelfPacedLearning.css';
import { Link } from 'react-router-dom';

const SelfPacedLearning = () => {
  return (
    <div className="selfPaced-container">
      <section className="selfPaced-title-section">
        <div className="selfPaced-title-container">
          <div className="selfPaced-title-content">
            <h1>Self-Paced Learning</h1>
            <h3 className="selfPaced-section-title">About the Program</h3>
            <p className="selfPaced-description">
              Welcome to our self-paced learning program for software
              engineering. With our specially curated recordings of bootcamp
              lessons, you can enhance your programming and development skills
              at your own pace. Our affordable program offers you the
              flexibility to learn software engineering concepts on your
              schedule. Whether you're a beginner looking to build a strong
              foundation or an experienced developer seeking to advance your
              expertise, our comprehensive library of bootcamp recordings has
              something for everyone.
            </p>
            <div className="selfPaced-how-container">
              <h3 className="selfPaced-section-title">How It Works</h3>
              <p className="selfPaced-description">
                Simply purchase access to our library of bootcamp lesson
                recordings and start your self-paced learning journey today.
              </p>
            </div>
          </div>

          <div className="selfPaced-image-container">
            <img
              src="selfPaced.jpg"
              alt="self-paced learning img"
              className="selfPaced-image"
            />
          </div>
        </div>
        <div className="selfLearning-contactUs-box">
          <Link to="/contactUs" className="selfLearning-contactUs-btn">
            Contact Us
          </Link>
          <p>
            Have questions or need more information? Feel free to reach out to
            us. Click on the "Contact Us" link above to access our contact form
            and connect with our team.
          </p>
        </div>
      </section>
    </div>
  );
};

export default SelfPacedLearning;
