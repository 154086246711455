import React from 'react';
import { Link } from 'react-router-dom';
import './styles/CategoryBoxes.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import coursesInfo from '../../data/coursesInfo.json';
import DateCalculator from '../DateCalculator'; // Ensure DateCalculator is imported correctly

const SoftwareEngineeringCourses = () => {
  const softwareEngineeringCourses = coursesInfo[0].courses.filter(
    (course) => course.category === 'software engineering'
  );

  const courseLinkMap = {
    '101 Intro to Web Development': '/101IntroToWebDevelopment',
    '201 Web Development and JavaScript Foundations':
      '/201WebDevAndJSFoundations',
    '301 Intermediate JavaScript and Web Development': '/301IntermJSandWebDev',
    '401 Expert Web Development': '/401ExpertWebDevelopment',
    '202 Java Fundamentals': '/202JavaFundamentals',
    '301 Expert Java Development with Spring and AWS':
      '/301ExpertJavaDevSpringAWS',
    '302 Algorithms and Data Structures': '/302AlgorithmsDataStructures',
    '401 AI and Machine Learning': '/401AIandMachineLearning',
  };

  const webDevelopmentCourses = softwareEngineeringCourses.filter(
    (course) => course.subCategory === 'web development'
  );

  const continuingEducationCourses = softwareEngineeringCourses.filter(
    (course) => course.subCategory === 'continuing education'
  );

  return (
    <div className="category-box-container">
      <h1>Software Engineering Courses</h1>

      <div className="course-category-title">Web Development Courses</div>
      <div className="course-category">
        {webDevelopmentCourses.map((course) => {
          // Safeguard against invalid course.schedule
          const schedule = Array.isArray(course.schedule)
            ? course.schedule
            : [];
          const { startDate, endDate } = DateCalculator({
            courseDuration: parseInt(course.duration.split(' ')[0], 10),
            futureMonths: 1,
            startDay: schedule[0]?.day,
            endDay: schedule[schedule.length - 1]?.day,
          });

          return (
            <div key={course.id} className="course-box">
              <Link
                to={courseLinkMap[course.courseName]}
                className="course-box-link"
              >
                <p className="course-name">{course.courseName}</p>
                <p className="course-delivery">{course.delivery}</p>
                <p className="course-startAndEnd">
                  {startDate} - {endDate}
                </p>
                <p className="course-schedule">
                  {schedule.length > 0
                    ? schedule.map((item, index) => (
                        <span key={index}>
                          {item.day} {item.time}
                          {index < schedule.length - 1 && ', '}
                        </span>
                      ))
                    : 'No schedule available'}
                </p>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chevron-icon"
                />
              </Link>
            </div>
          );
        })}
      </div>

      <div className="course-category-title">Continuing Education Courses</div>
      <div className="course-category">
        {continuingEducationCourses.map((course) => (
          <div key={course.id} className="course-box">
            <Link
              to={courseLinkMap[course.courseName]}
              className="course-box-link"
            >
              <p className="course-name">{course.courseName}</p>
              <p className="course-delivery">{course.delivery}</p>
              <p className="course-startAndEnd">{course.startAndEnd}</p>
              <p className="course-schedule">
                {Array.isArray(course.schedule)
                  ? course.schedule.map((item, index) => (
                      <span key={index}>
                        {item.day} {item.time}
                        {index < course.schedule.length - 1 && ', '}
                      </span>
                    ))
                  : 'No schedule available'}
              </p>
              <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoftwareEngineeringCourses;
