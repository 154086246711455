import React, { useCallback, useState } from 'react';
import './styles/ApplicationForm.css';
import emailjs from 'emailjs-com';
import environmentVariables from '../EnvironmentVariables.json';
import { useNavigate } from 'react-router-dom';
import Captcha from './Captcha';

import coursesInfo from '../../data/coursesInfo.json';

const ApplicationForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [race, setRace] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [course, setCourse] = useState('');
  const [classroom, setClassroom] = useState('');
  const [skill, setSkill] = useState('');
  const [experience, setExperience] = useState('');
  const [consideration, setConsideration] = useState('');
  const [education, setEducation] = useState('');
  const [study, setStudy] = useState('');
  const [careerPath, setCareerPath] = useState([]);
  const [contactMethod, setContactMethod] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);

  const navigate = useNavigate();

  const handleCareerPathChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCareerPath((prevPath) => [...prevPath, value]);
    } else {
      setCareerPath((prevPath) => prevPath.filter((path) => path !== value));
    }
  };

  const handleContactMethodChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setContactMethod((prevMethod) => [...prevMethod, value]);
    } else {
      setContactMethod((prevMethod) =>
        prevMethod.filter((method) => method !== value)
      );
    }
  };

  const handleCaptchaChange = useCallback((value) => {
    setCaptchaValue(value);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert('Please complete the CAPTCHA before submitting.');
      return;
    }

    const serviceId = environmentVariables.secondAccount.serviceId;
    const templateId =
      environmentVariables.secondAccount['application-templateId'];
    const publicKey = environmentVariables.secondAccount.publicKey;

    const templateParams = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      to_name: 'Farhad',
      phone: phone,
      DOB: dob,
      gender: gender,
      race: race,
      street_address: address,
      state: state,
      city: city,
      zip: zip,
      career_path_choice: careerPath,
      course: course,
      class_format: classroom,
      skill_level: skill,
      engineer_experience: experience,
      consideration: consideration,
      education: education,
      major: study,
      contact_method: contactMethod,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        // Clear form fields
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setDob('');
        setGender('');
        setRace('');
        setAddress('');
        setState('');
        setCity('');
        setZip('');
        setCareerPath([]);
        setCourse('');
        setClassroom('');
        setSkill('');
        setExperience('');
        setConsideration('');
        setEducation('');
        setStudy('');
        setContactMethod([]);

        alert('Application submitted successfully!');
        navigate('/');
      });
  };

  const courseOptions = coursesInfo[0].courses.map((course) => (
    <option key={course.id} value={course.courseName}>
      {course.courseName}
    </option>
  ));

  return (
    <div className="container-apply-form">
      <h1>Ahmed Tech Academy Application</h1>
      <div className="form-section">
        <form onSubmit={handleSubmit}>
          <div className="apply-section-title">Personal Information</div>
          <div className="form-group inline">
            <div className="form-group">
              <label htmlFor="first-name">First Name *</label>
              <input
                id="first-name"
                type="text"
                name="first_name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name *</label>
              <input
                id="last-name"
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone *</label>
            <input
              id="phone"
              type="tel"
              name="phone"
              placeholder="555-555-5555"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth *</label>
            <input
              id="dob"
              type="text"
              name="dob"
              placeholder="mm/dd/yyyy"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender">Gender *</label>
            <select
              id="gender"
              value={gender}
              placeholder="--Select an option"
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option disabled value="">
                ----Select an option
              </option>
              <option>Male</option>
              <option>Female</option>
              <option>Other</option>
              <option>Prefer not to say</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="race">Race *</label>
            <input
              id="race"
              type="text"
              name="race"
              placeholder="Race"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              required
            />
          </div>

          <div className="apply-section-title">Mailing Address</div>
          <div className="form-group">
            <label htmlFor="street-address">Street Address *</label>
            <input
              id="address"
              type="address"
              name="address"
              placeholder="1234 ATA Street, Apartment/Studio/Floor"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City *</label>
            <input
              id="city"
              type="text"
              name="city"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
          <div className="form-group inline">
            <div className="form-group">
              <label htmlFor="state">State *</label>
              <input
                id="state"
                type="text"
                name="state"
                placeholder="State/Province"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="zip">Zip*</label>
              <input
                id="zip"
                type="text"
                name="zip"
                placeholder="Zip/Postal Code"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="apply-section-title">General Information</div>
          <div className="form-group">
            <label htmlFor="career-path">
              Which career path are you interested in? Select all that apply: *
            </label>
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="software-engineer"
                name="career-path"
                value="Software Engineer"
                onChange={handleCareerPathChange}
              />
              <label htmlFor="software-engineer">Software Engineer</label>
              <input
                type="checkbox"
                id="cybersecurity-engineer"
                name="career-path"
                value="CyberSecurity Engineer"
                onChange={handleCareerPathChange}
              />
              <label htmlFor="cybersecurity-engineer">
                CyberSecurity Engineer
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="course">Which course are you applying for? *</label>
            <select
              id="course"
              value={course}
              placeholder="--Select your course"
              onChange={(e) => setCourse(e.target.value)}
              required
            >
              <option disabled value="">
                --Select your course
              </option>
              {courseOptions}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="classroom-experience">
              What is your preferred classroom experience? *
            </label>
            <select
              id="classroom"
              value={classroom}
              placeholder="--Select an option"
              onChange={(e) => setClassroom(e.target.value)}
              required
            >
              <option disabled value="">
                --Select an option
              </option>
              <option>Online</option>
              <option>In-person</option>
              <option>No preference</option>
              <option>Not sure</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="skill-level">
              What is your current skill level? *
            </label>
            <select
              id="skill"
              value={skill}
              placeholder="--Select an option"
              onChange={(e) => setSkill(e.target.value)}
              required
            >
              <option disabled value="">
                --Select an option
              </option>
              <option>Beginner</option>
              <option>Intermediate</option>
              <option>Advanced</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="experience">
              Tell us about your engineering experience so far *
            </label>
            <textarea
              id="experience"
              name="experience"
              className="large-textarea"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              required
            ></textarea>
            <div className="note">Max 2,000 characters.</div>
          </div>
          <div className="form-group">
            <label htmlFor="special-considerations">
              Do you have any special learning considerations or disabilities?
              If so, please describe them. *
            </label>
            <textarea
              id="consideration"
              name="consideration"
              className="large-textarea"
              value={consideration}
              onChange={(e) => setConsideration(e.target.value)}
              required
            ></textarea>
            <div className="note">Max 2,000 characters.</div>
          </div>
          <div className="form-group">
            <label htmlFor="education">
              What is the highest level of education you have received? *
            </label>
            <textarea
              id="education"
              name="education"
              className="small-textarea"
              value={education}
              onChange={(e) => setEducation(e.target.value)}
              required
            ></textarea>
            <div className="note">Max 200 characters.</div>
          </div>
          <div className="form-group">
            <label htmlFor="study">What was your area of study? *</label>
            <textarea
              id="study"
              name="study"
              className="small-textarea"
              value={study}
              onChange={(e) => setStudy(e.target.value)}
              required
            ></textarea>
            <div className="note">Max 200 characters.</div>
          </div>

          <div className="apply-section-title">Contact</div>
          <div className="form-group">
            <label htmlFor="preferred-contact">
              I prefer to be contacted via: *
            </label>
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="contact-phone"
                name="contact-method"
                value="Phone"
                onChange={handleContactMethodChange}
              />
              <label htmlFor="contact-phone">Phone</label>
              <input
                type="checkbox"
                id="contact-email"
                name="contact-method"
                value="Email"
                onChange={handleContactMethodChange}
              />
              <label htmlFor="contact-email">Email</label>
              <input
                type="checkbox"
                id="contact-text"
                name="contact-method"
                value="Text"
                onChange={handleContactMethodChange}
              />
              <label htmlFor="contact-text">Text</label>
            </div>
          </div>
          <div className="application-captcha">
            <Captcha onChange={handleCaptchaChange} />
          </div>
          <button id="submit-btn" className="submit-btn" type="submit">
            Submit Application
          </button>
        </form>
      </div>
    </div>
  );
};

export default ApplicationForm;
